@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }



.champion-stats {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 1200px; /* 최대 너비 제한 */
    margin: 0 auto; /* 화면 가운데 정렬 */

    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.champion-stats h2 {
    text-align: center;
    color: #333;
}

.champion-stats p {
    text-align: center;
    font-size: 1.1rem;
    color: #555;
}

.champion-stats table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    table-layout: fixed; /* 테이블 너비 고정 */
}

.champion-stats th, .champion-stats td {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 0.95rem;
}

.champion-stats th {
    background-color: #f4f4f4;
    cursor: pointer;
}

.champion-stats th:hover {
    background-color: #e2e2e2;
}

.champion-stats tbody tr:hover {
    background-color: #f7f7f7;
}

.champion-stats .details-row {
    background-color: #f9f9f9;
}

.champion-stats .details-box {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin-top: 10px;
}

.champion-stats .details-box table {
    width: 100%;
    border-collapse: collapse;
}

.champion-stats .details-box th, .details-box td {
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 0.9rem;
}

.champion-stats .details-box th {
    background-color: #f1f1f1;
}

.champion-stats .details-box tbody tr:hover {
    background-color: #e9e9e9;
}

.champion-stats img {
    border-radius: 10%;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    vertical-align: middle; /* 이미지와 글자가 수평으로 정렬되도록 */
}

.champion-stats .details-box img {
    width: 25px;
    height: 25px;
    margin-right: 6px;
}

.champion-stats .details-box .position-icon {
    width: 32px;
    height: 32px;
}

.champion-stats .ChampionTd{
    vertical-align: middle; /* 내용이 세로로 가운데 정렬됨 */
    text-align: left;
}

.champion-stats-title p{
    text-align: right;
}

.champion-stats h2{
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

/* 모바일 스타일 */
@media (max-width: 768px) {
    .champion-stats {
        padding: 10px;
        font-size: 0.9rem;
    }

    .champion-stats h2 {
        font-size: 1.2rem;
    }

    .champion-stats-title p {
        text-align: center; /* 제목 텍스트를 가운데 정렬 */
        font-size: 0.95rem;
    }

    .champion-stats table {
        font-size: 0.85rem;
    }

    .champion-stats th, .champion-stats td {
        padding: 8px; /* 셀 간격 축소 */
    }

    .champion-stats img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
    }

    .champion-stats .details-box img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }

    .champion-stats .details-box {
        padding: 15px;
    }

    /* 테이블 너비가 좁은 화면에서 스크롤 가능하도록 설정 */
    .champion-stats table {
        display: block;
        overflow-x: auto;
        white-space: nowrap; /* 줄바꿈 방지 */
    }

    .champion-stats .details-box table {
        display: block;
        overflow-x: auto;
        white-space: nowrap; /* 줄바꿈 방지 */
    }

    /* 모바일에서 테이블 글꼴 축소 */
    .champion-stats .details-box th, 
    .champion-stats .details-box td {
        padding: 6px; /* 셀 간격 축소 */
        font-size: 0.8rem;
    }
    
    /* 제목 글자 크기 축소 */
    .champion-stats h2 {
        font-size: 1.4rem;
    }
    
    .champion-stats .ChampionTd {
        text-align: left;
        vertical-align: middle;
    }
}
