.uploadComponent {
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .uploadComponent h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .uploadForm .formGroup {
    margin-bottom: 15px;
  }
  
  .uploadForm label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .uploadForm input,
  .uploadForm textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .uploadForm button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .uploadForm button:hover {
    background-color: #45a049;
  }
  
  .statusMessage {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
  }
  