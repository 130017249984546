@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
*{
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
.container{
    width: 90%;
    margin: auto;
    margin-top: 150px;
}
.history{
    width: 900px;
    display: flex;
    flex-direction: row;  /* 요소들을 가로로 배치 */
    flex-wrap: wrap;       /* 요소들이 넘칠 경우 다음 줄로 자동 이동 */
    justify-content: space-around;
    gap: 10px;             /* 요소 간 간격 */
}
.history .gameid{
    font-size: 12px;
    text-align: right;
}
.history .game-block{
    width: 400px;
}
.history .game-block-child{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 400px;
    margin-bottom: 30px;
    border: 1px solid black;
}
.history{
    margin: auto;
}
.history .redteam p, .history .blueteam p{
    display: flex;
    justify-content: space-between;
}
.history table{
    width: 200px;
    border-collapse: collapse
}
.history p{
    margin: 0;
}

.history td, .history tr{
    text-align: center;
}
.history td p{
    display: inline;
}

.history .redimgtd{
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 5px;
}
.history .blueimgtd{
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 5px;
}
.history .blueimgtd p, .history .redimgtd p{
    margin-right: 5px;
    margin-left: 5px;
    font-weight: 700;
    font-style: normal;
}
.history .cropping{
    max-width: 32px;
    max-height: 32px;
    overflow: hidden;
    border-radius: 100px;
}

.history .championImg{
    width: 36px;
    margin-left: -2px;
    margin-top: -2px;
}

.blueteamkill{
    text-align: right;
}

.history .sideTh{
    width: 75px;
}

@media (max-width: 768px) {
    .container{
        width: 90%;
        margin: auto;
        margin-top: 150px;
    }
    .history{
        width: 100%;
        display: flex;
        flex-direction: column;  /* 요소들을 가로로 배치 */
        justify-content: center;
        gap: 10px;             /* 요소 간 간격 */
        margin: auto;
    }

    .history .game-block{
        width: 100%;
    }

    .history .game-block-child{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid black;
        font-size: 13px;
    }
    .history table{
        width: 100%;
        border-collapse: collapse;
        font-size: 12px;
    }
    .history .blueteam, .history .redteam{
        width: 100%;
    }
}