@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Pretendard-Regular', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.highlightComponent {
  width: 1400px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.highlightComponent .game-block {
  width: 640px;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}

.highlightComponent .gameid {
  font-size: 14px;
  text-align: right;
  font-weight: 600;
  margin-bottom: 10px;
}

.highlightComponent .videoContainer iframe {
  width: 640px;
  height: 360px;
  border-radius: 8px;
  border: 0;
  margin-bottom: 20px;
}

.highlightComponent .game-record .game-block-child {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.highlightComponent .game-record .blueteam, .highlightComponent .game-record .redteam {
  width: 320px;
  margin-bottom: 15px;
}

.highlightComponent .game-record p {
  font-weight: 700;
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.highlightComponent .game-record .blueimgtd, .highlightComponent .game-record .redimgtd {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 5px;
}

.highlightComponent .game-record .blueimgtd {
  justify-content: right;
  padding-right: 5px;
}

.highlightComponent .game-record .cropping {
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
  border-radius: 100px;
}

.highlightComponent .game-record .championImg {
  width: 36px;
  margin-left: -2px;
  margin-top: -2px;
}

.highlightComponent .game-record table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.highlightComponent .game-record th, .highlightComponent .game-record td {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.highlightComponent .game-record th {
  font-weight: 600;
}

.highlightComponent .game-record td {
  font-size: 14px;
}

.highlightComponent .game-record .sideTh {
  width: 75px;
}

.highlightComponent .blueTeamKill, .highlightComponent .redTeamKill {
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .highlightComponent {
      width: 100%;
  }

  .highlightComponent .game-block {
      width: 100%;
  }

  .highlightComponent .videoContainer iframe {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    border: 0;
    margin-bottom: 20px;
  }

  .highlightComponent .game-record .game-block-child {
      flex-direction: column;
  }

  .highlightComponent .game-record table {
      width: 100%;
      font-size: 12px;
  }

  .highlightComponent .game-record p {
      font-size: 14px;
  }

  .highlightComponent .game-record .blueteam, .highlightComponent .game-record .redteam {
    display: none;
  }
}
