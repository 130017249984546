@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
*{
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.container{
    width: 90%;
    margin: auto;
    margin-top: 120px;
    
}
.player{
    margin: 15px;
    width: 340px;
    border: 1px solid black;
}
.player-info{
    display: flex;
    flex-direction: row;
    background-color: #608BC1;
    padding: 10px;
}
.player-info strong{
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-size: 2.1rem;
    margin-right: 10px;
    display: flex; align-items: center;
}
.champion-win-rates{
    display: flex;
    flex-direction: row;  /* 요소들을 가로로 배치 */
    flex-wrap: wrap;       /* 요소들이 넘칠 경우 다음 줄로 자동 이동 */
    gap: 10px;             /* 요소 간 간격 */
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
}
.playerDataParents{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
    justify-content: center;
}
.champion{
    width: 95px;
    border: 1px solid #002453;
}
.champion-win-rates .cropping{
    max-height: 30px;
    max-width: 95px;
    overflow: hidden;
  }
.champion-win-rates .championImg{
    width: 100px;
    max-width: initial;
    margin-top: -27.5px;
    margin-left: -2.5px;
}
.champion-name{
    width: 95px;
    background-color: #002453;
    color: white;
    text-align: center;
    margin-bottom: 5px;
    font-size: 14px;
}
.champion-kda, .champion-record{
    width: 100%;
    padding-left: 2px;
    letter-spacing: -0.5px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 2px;
}

.champion-kda{
    font-size: 11px;
    letter-spacing: -0.7px;
}

.champion-kda{
    margin-bottom: 5px;
}

a{
    text-decoration: none;
    color: white;
}

.playerDataSearchBox{
    width: 100%;
    display: flex;
    justify-content: center;
}
.playerDataSearchBox .search-input{
    width: 200px;
    height: 40px;
    text-align: center;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.toggle-button{
    width: 100%;
    border: 0;
    background-color:steelblue;
}

/* 모바일에서의 레이아웃 */
@media (max-width: 768px) {
    .playerDataParents .container {
        width: 95%; /* 모바일에서 공간을 좀 더 많이 사용 */
        margin-top: 60px; /* 상단 여백을 줄임 */
    }
    .playerDataParents .champion-name{
        font-size: 10px;
        width: 100%;
    }
    .playerDataParents .champion-record, .playerDataParents .champion-kda{
        font-size: 8px;
        padding: 0;
    }
    .playerDataParents .champion{
        width: 60px;
        border: 1px solid #002453;
    }
    .playerDataParents .champion-win-rates .cropping{
        max-height: 30px;
        max-width: 90px;
        overflow: hidden;
      }
    .playerDataParents .champion-win-rates .championImg{
        width: 75px;
        max-width: initial;
        margin-top: -20px;
        margin-left: -5px;
        
    }
    .playerDataParents .champion-win-rates{
        display: flex;
        justify-content: center;
        margin-left: 0;
    }
    .player-info strong{
        font-size: 30px;
    }
    .player-stats{
        font-size: 14px;
    }
}