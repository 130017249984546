@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
.rankComponent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rankComponent h2{
    font-weight: bold;
}
.rankComponent table{
    border-collapse: collapse
}
.rankComponent th, tr{
    width: 70px;
    height: 29px;
    text-align: center;
}
.rankComponent th{
    border-bottom: 1px solid black;
}
.rankComponent thead th{
    font-weight: bold;
    font-size: 18px;
}
.rankComponent th button{
        font-size: 18px;
        border: 0;
        background-color: transparent;
        font-weight: bold;
}

.rankComponent .assists{
    width: 97px;
}
.rankComponent .avgKills{
    width: 80px;
}
.rankComponent .avgDeaths{
    width: 92px;
}
.rankComponent .avgAssists{
    width: 123px;
}
.rankComponent .championCount{
    width: 92px;
}
.rankComponent .rankComponentTop{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 600px;
}

.rankComponent .searchBox{
    margin-bottom: 30px;
}

/* 전체 입력 박스 스타일 */
.rankComponent input[type="text"] {
    width: 150px;  /* 전체 너비 사용 */
    height: 15px;
    padding: 10px 15px;  /* 여백 */
    border: 2px solid #ddd;  /* 테두리 */
    border-radius: 25px;  /* 둥근 테두리 */
    font-size: 16px;  /* 글자 크기 */
    color: #333;  /* 글자 색상 */
    background-color: #f9f9f9;  /* 배경 색상 */
    transition: all 0.3s ease;  /* 변화 애니메이션 */
    text-align: center;
}

/* 입력이 포커스될 때 스타일 */
.rankComponent input[type="text"]:focus {
    outline: none;  /* 기본 파란색 테두리 제거 */
    border-color: #9cc4ff;  /* 테두리 색상 변경 */
    background-color: #fff;  /* 배경 색상 변경 */
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);  /* 포커스된 상태의 그림자 효과 */
}

/* 입력 필드에 텍스트를 입력할 때 스타일 */
.rankComponent input[type="text"]:not(:placeholder-shown) {
    border-color: #9cc4ff;  /* 텍스트 입력 후 테두리 색상 변경 */
}

/* 입력 필드에 힌트(placeholder) 스타일 */
.rankComponent input[type="text"]::placeholder {
    color: #aaa;  /* 힌트 텍스트 색상 */
}

@media (min-width: 769px) {
    .rankingTable-mobile {
        display: none;
    }
}

/* 모바일 반응형 처리 */
@media (max-width: 768px) {
    .rankingTable {
        display: none;
    }

    .rankComponent .rankComponentTop{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }

    .rankingTable-mobile {
      width: 100%;
      overflow-x: auto;
      display: block;
    }
  
    .rankingTable-mobile th,
    .rankingTable-mobile td {
      white-space: nowrap;
      font-size: 12px; /* 폰트 크기 조정 */
    }
  
  
    /* 첫 번째와 두 번째 열은 고정하고 나머지 열만 스크롤 */
    .sticky-col {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 2;
    }
  }
  
  @media (max-width: 480px) {
    /* 모바일 화면에서 더 작은 조정 */
    .rankingTable-mobile th,
    .rankingTable-mobile td {
      font-size: 10px; /* 더 작은 폰트 */
    }
  }