.champion-gallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}
.champion-gallery .filter-options {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .champion-gallery .filter-options label {
    font-size: 16px;
    cursor: pointer;
  }
  
  .champion-gallery .filter-options input[type="radio"] {
    margin-right: 5px;
  }
  
  .champion-gallery .champion-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .champion-gallery .champion {
    text-align: center;
    width: 100px;
    padding-top: 10px;
  }
  
  .champion-gallery .champion-img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .champion-gallery .champion-img.grayscale {
    filter: grayscale(100%);
  }
  
  .champion-gallery .champion-img.color {
    filter: none;
  }
  
  .champion-gallery .champion-img:hover {
    transform: scale(1.1);
  }
  
  .champion-gallery .champion-name {
    margin-top: 5px;
    font-size: 13px;
    width: 100%;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .champion-gallery .champion {
        text-align: center;
        width: 70px;
        padding-top: 10px;
      }
      .champion-gallery .champion-img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        transition: transform 0.3s ease;
      }
  }