.header{
    position: fixed;
    top: 0; /* 화면 상단에 고정 */
    left: 0;
    width: 100%; /* 전체 너비 */
    background-color: #007bff;
    padding: 10px 0; /* 상하 패딩 */
    z-index: 1000; /* 다른 요소들 위에 겹치도록 설정 */
    justify-content: center;
}

.header .header-par p{
    font-size: 1.2rem;
    text-decoration: none;
    color: white;
    font-weight: bold;
}

a{
    text-decoration: none;
}
.header p:link{
    font-size: 1.2rem;
    text-decoration: none;
    color: white;
}

.header-par{
    margin: auto;
    width: 900px;
    display: flex;
    justify-content: space-around;
    
}

.headerTip{
    text-align: center;
    color: rgb(255, 255, 255) !important;
    padding: auto;
    font-size: 12px !important;
    background-color: #3f3f3f;
    margin-bottom: -10px;
    padding: 5px;
}

/* 데스크탑 화면에서 모바일 헤더 숨기기 */
@media (min-width: 769px) {
    .mobile-header {
        display: none;
    }

    .header {
        display: block;
    }
}

/* 모바일 화면에서 데스크탑 헤더 숨기기 */
@media (max-width: 768px) {
    .header {
        display: none;
    }

    .mobile-header {
        display: block;
    }
}

/* 모바일에서 헤더가 펼쳐졌을 때 */
@media (max-width: 768px) {
    .mobile-header .header-par {
        padding-top: 10px;
    }
    
    .mobile-header .header-par img {
        cursor: pointer;
    }

    .mobile-header{
        position: fixed;
        top: 0; /* 화면 상단에 고정 */
        left: 0;
        width: 100%; /* 전체 너비 */
        background-color: #007bff;
        padding-top: 10px; /* 상하 패딩 */
        z-index: 1000; /* 다른 요소들 위에 겹치도록 설정 */
        justify-content: center;
    }

    .mobile-header .header-par {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mobile-header .header-par p {
        font-size: 1rem;
    }

    .mobile-header .headerTip {
        font-size: 11px !important;
    }

    .mobile-header .header-par img {
        cursor: pointer; /* 로고 클릭 시 메뉴 열리도록 */
    }

    .mobile-header .menu {
        display: none; /* 기본적으로 메뉴 숨김 */
    }

    .mobile-header .menu.open {
        display: flex; /* 모바일에서 메뉴가 열릴 때만 보이도록 */
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 10px;
    }

    .mobile-header a{
        text-align: center;
    }
}
