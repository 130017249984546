@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

.teamContainer {
    text-align: center;
    padding: 20px;
    width: 1000px;
    margin: auto;
    width: 80%;
    margin-top: -40px;
  }
  
  .teamContainer .player-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .teamContainer .player-card {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: move;
    width: 120px;
    text-align: center;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .teamContainer .player-card:hover {
    background-color: #e0e0e0;
  }
  
  .teamContainer .position-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px;
  }
  
  .teamContainer .position-drop-area {
    padding: 15px;
    min-height: 100px;
    width: 200px;
    background-color: #fafafa;
    border: 1px dashed #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .teamContainer .assigned-player {
    display: flex;
    justify-content: space-between;
    background-color: #d0f0c0;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .teamContainer .remove-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .teamContainer .remove-btn:hover {
    background-color: darkred;
  }
  
  .teamContainer .assign-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    margin-right: 5px;
  }
  
  .teamContainer .assign-btn:hover {
    background-color: #0056b3;
  }
  
  .teamContainer .team-result {
    margin-top: 30px;
  }
  
  .teamContainer .team-table {
    width: 95%;
    margin:auto;
    margin-top: 20px;
    border-collapse: collapse;
    font-family: 'Pretendard-Bold'; 
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  .teamContainer .team-table th, .teamContainer .team-table td {
    padding: 10px;
    text-align: center;
  }

  .teamContainer .team-table td{
    border: 1px solid #ccc;
    width: 400px;
  }
  
  .teamContainer .team-table .position{
    width: 100px;
    background-color: #292929;
  }
  .teamContainer .team-table .blue {
    background-color: #007bff;
    color: white;
  }

  .teamContainer .team-table .red {
    background-color: #ff3300;
    color: white;
  }

  .teamContainer .team-table .middle {
    background: linear-gradient(to right, #007bff, #ff3300);
    color: white;
  }
  
  .teamContainer .team-table td {
    background-color: #f9f9f9;
  }

  .teamContainer .add-player-input{
    width:93px;
    height: 35px;
    text-align: center;
  }

  .teamContainer .add-player-btn{
    width: 41px;
    height: 41px;
  }
  
  .teamContainer .copy-btn{
    background-color: white;
    border: 0;
  }
  
  .teamContainer .team-result-title{
    font-family: 'Pretendard-Bold'; 
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  .teamContainer .reset-btn {
    background-color: #3f3f3f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 5px;
  }
  
  .teamContainer .reset-btn:hover {
    background-color: #707070;
  }

  @media (max-width: 768px) {
    .teamContainer h1{
      font-size: 23px;
    }
    .teamContainer .player-card{
      width: 50px;
    }
    .teamContainer .position-container{
      width: 100%;
    } 
    .teamContainer .position-drop-area{
      font-size: 10px;
      width: 60px;
    }
  }