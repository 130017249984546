/* winrate.css */

/* 기본 스타일 설정 */
.winRateDiv {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
}

.winRateDiv h2 {
    font-size: 24px;
    color: #333;
}

.winRateDiv h3 {
    font-size: 20px;
    color: #555;
}

/* 플레이어 선택 드롭다운 */
.winRateDiv select {
    padding: 8px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
    width: 200px;
}

.winRateDiv option {
    font-size: 14px;
}

/* 테이블 스타일 */
.winRateDiv table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.winRateDiv th,
.winRateDiv td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 14px;
}

/* 테이블 헤더 스타일 */
.winRateDiv th {
    background-color: #f1f1f1;
    font-weight: bold;
    cursor: pointer;
}

.winRateDiv th:hover {
    background-color: #e0e0e0;
}


/* 테이블 행 클릭 시 강조 효과 */
.winRateDiv tr:hover {
    background-color: #f1f1f1;
}

/* 정렬 아이콘 */
.winRateDiv th::after {
    font-size: 10px;
    color: #888;
}

/* 로딩 메시지 */
.winRateDiv .loading {
    text-align: center;
    font-size: 18px;
    color: #555;
    font-weight: bold;
}

/* 기본 색상과 스타일 */
.winRateDiv button,
.winRateDiv input[type="submit"],
.winRateDiv select {
    cursor: pointer;
}

.winRateDiv button:hover,
.winRateDiv input[type="submit"]:hover,
.winRateDiv select:hover {
    background-color: #e0e0e0;
}

.winRateDiv .team{
    color: dodgerblue;
}
.winRateDiv .enemy{
    color: #750000
}

/* winrate.css */

/* 모바일 스타일 (화면 너비가 768px 이하일 때) */
@media screen and (max-width: 768px) {
    /* 전체 컨테이너 스타일 */
    .winRateDiv {
        padding: 10px;
        font-size: 14px;
    }

    .winRateDiv h2 {
        font-size: 20px;
    }

    .winRateDiv h3 {
        font-size: 16px;
    }

    /* 드롭다운 메뉴 */
    .winRateDiv select {
        width: 100%;
        font-size: 12px;
        padding: 6px;
    }

    /* 테이블 스타일 */
    .winRateDiv table {
        width: 100%;
        font-size: 12px;
    }

    .winRateDiv th,
    .winRateDiv td {
        padding: 8px;
        font-size: 12px;
    }

    /* 테이블 헤더 */
    .winRateDiv th {
        font-size: 12px;
        padding: 10px 5px;
    }

    /* 테이블 줄이기: 세로 스크롤 허용 */
    .winRateDiv table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    /* 정렬 아이콘 줄이기 */
    .winRateDiv th::after {
        font-size: 8px;
    }

    /* 강조 스타일 */
    .winRateDiv tr:hover {
        background-color: #f7f7f7;
    }
}
